
<template>
  <div class="footer">
    <div class="footer_logo">
      <img src="/img/head_logo.png">
    </div>
    <div class="footer_flex">
      <p @click="link_to('CustomerTokushoPage')">特定商取引法に関する表示</p>
      <p @click="link_to('CustomerTOSPage')">ご利用規約</p>
      <p @click="link_to('CustomerPolicyPage')">プライバシーポリシー</p>
    </div>
    <div class="copyright">
      <p>(C) 2024 WIN-WIN カード All Rights Reserved.</p>
    </div>
  </div> 
</template>

<script>
  export default {
    data () {
      return {
      }
    },
    methods: {
      link_to(page_name){
        if(this.$route.query.sid){
          let query_parm = {
            sid:this.$route.query.sid,
          }
          this.$router.push({name:page_name, query:query_parm});
          } else {
          this.$router.push({name:page_name});
        }
      }
      // link_to (page_name) {
      //   this.$router.push({name:page_name}); 
      // },
    }
  }
</script>
  

<style>
  .footer{width: 100%;height: auto;display: block;bottom: 0;z-index: 999;}
  .footer_logo{padding: 20px;text-align: center;background-color:rgb(98,239,222)}
  .footer_logo img{width: 40%;}
  .footer_flex{background-color:rgb(98,239,222);display: flex;justify-content: center;height: 50px;}
  .footer_flex p{margin: 0 10px;font-weight: bold;}
  .copyright{background-color: rgb(255, 124, 240);color: white;text-align: center;padding: 10px;}  

  @media (max-width: 600px) {
    .button span {display: initial;}
    .footer_flex {
      display: block;
      height: auto;
      padding-bottom: 20px;
    }
    .footer_flex p{
      text-align: center;
      margin: 0 10px;
      padding: 10px;
    }
    .footer_logo img{width: 100%;}
  }



</style>