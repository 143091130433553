<template>
    <div class="image_block">
        <div class="image_box">
            <img id="bg_image" :src="$commonFunction.return_s3_path(scratch_data.bg_image)">
            <div id="temp_logo_box">
                <img :src="$commonFunction.return_s3_path(scratch_data.logo_image)">
            </div>
            <div class="prize_box" v-show="!is_enable">
                <p>このくじの有効期限は</p><br>
                <p>{{datetime_format(scratch_data.start_date)}}</p><br>
                <p> ~ </p><br>
                <p>{{datetime_format(scratch_data.end_date)}}</p><br>
                <p>です。</p>
            </div>
            <div class="prize_box" v-show="is_enable">     
                <img class="prize_bg_img" :src="$commonFunction.return_s3_path(scratch_data.prize_box_image)">  
                <h1>{{scratch_data.title}}</h1>
                <p style="margin-bottom: 200px;">下記図柄が縦横斜めいずれかに、3つ揃えば当たりです！</p>
                <img src="/img/scratch_sample.png" class="scratch_sample">
                <div class="prize_row" v-if="scratch_data['1p_start_count'] > 0">
                    <img :src="$commonFunction.return_s3_path(scratch_data.prize1_image)">
                    <p v-html="decorate_text(scratch_data.first_prize_label)"></p>
                </div>
                <div class="prize_row" v-if="scratch_data['2p_start_count'] > 0">
                    <img :src="$commonFunction.return_s3_path(scratch_data.prize2_image)">
                    <p v-html="decorate_text(scratch_data.second_prize_label)"></p>
                </div>
                <div class="prize_row" v-if="scratch_data['3p_start_count'] > 0">
                    <img :src="$commonFunction.return_s3_path(scratch_data.prize3_image)">
                    <p v-html="decorate_text(scratch_data.third_prize_label)"></p>
                </div>
                <div class="prize_row" v-if="scratch_data['4p_start_count'] > 0">
                    <img :src="$commonFunction.return_s3_path(scratch_data.prize4_image)">
                    <p v-html="decorate_text(scratch_data.fourth_prize_label)"></p>
                </div>
                <div class="prize_row" v-if="scratch_data['5p_start_count'] > 0">
                    <img src="/img/miss_prize.png">
                    <p v-html="decorate_text(scratch_data.fifth_prize_label)"></p>
                </div>
            </div>
            <div v-if="is_enable && !is_request" class="button_box" :style="{ borderColor: scratch_data.color }">
                <button @click="modal_control(0)" :style="{ backgroundColor: scratch_data.color }">スクラッチに挑戦</button>
                <p style="margin-top: 20px;" class="error_message">{{error_message}}</p>
            </div>
        </div>
    </div>
    <!-- モーダルブロック -->
    <InfoModalBlock @onClick="point_purchase" :list_items="block_data"></InfoModalBlock>
    <CustomerFooterBlock :template_id="scratch_data.template_id" class="print_ignore"></CustomerFooterBlock>
</template>
  
<script>
import InfoModalBlock from "@/components/blocks/custom/InfoModalBlock";
import CustomerFooterBlock from "@/components/blocks/custom/CustomerFooterBlock";
export default {
    components: {
        InfoModalBlock,
        CustomerFooterBlock,
    },
    data() {
        return {
            block_data:{
                modal_condition:0,
                price:200
            },
            is_request:false,
            is_enable:false,
            board:[],
            isScratching: false,
            is_active_page: true,
            ctx: null,
            error_message: "",
            scratch_data:{template_id:6}
        };
    },
    mounted() {
        this.process()
    },
    methods: {
        async process(){
            // くじの情報を取得する
            let response = await this.$commonFunction.axios_get(`customer/scratch/detail/${this.$route.query.sid}`)
            if(response.status === 200 && response.data.item_data.length > 0){
                this.scratch_data = response.data.item_data[0]
                const startDate = Date.parse(this.scratch_data.start_date);
                const endDate = Date.parse(this.scratch_data.end_date);
                let now = new Date().getTime();
                now += 32400000
                this.is_enable = now >= startDate && now <= endDate;
            } else if (response.status == 500){
                this.$router.push({name:"ServerErrorPage"});
            } else {
                this.$router.push({name:"CustomerDisablePage"});
            }
        },
        // Customer登録の確認
        // async scratch_entry(){
        //     let storedUUID = localStorage.getItem('uniqueUUID');
        //     if (!storedUUID) {
        //         storedUUID = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        //         const r = Math.random() * 16 | 0,
        //                 v = c == 'x' ? r : (r & 0x3 | 0x8);
        //             return v.toString(16);
        //         });
        //         localStorage.setItem('uniqueUUID', storedUUID);
        //     }
        //     let post_data = {
        //         uuid:storedUUID,
        //         scratch_id:this.scratch_data.id,
        //     }
        //     this.error_message = ""
        //     let response = await this.$commonFunction.axios_post(`user/scratch/customer/add`,post_data)
        //     if(response.status === 200){
        //         let query_parm = {
        //             sid:this.$route.query.sid,
        //             scratch_code:response.data.scratch_code
        //         }
        //         this.$router.push({name:'CustomerScratchPage', query:query_parm});
        //     } else {
        //         this.error_message = this.scratch_data.reset_status == 1 ? "くじは1日に１回までです。" : "くじを引けるのは1回限りです"
        //     }
        // },
        // 日時をフォーマットする
        datetime_format(dateString){
            const date = new Date(dateString);
            const year = date.getUTCFullYear();
            const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
            const day = date.getUTCDate().toString().padStart(2, '0');
            const hours = date.getUTCHours().toString().padStart(2, '0');
            const minutes = date.getUTCMinutes().toString().padStart(2, '0');
            return `${year}年${month}月${day}日 ${hours}:${minutes}`;
        },
        // S3のパスを取得
        return_s3_path(path){
            return `${process.env.VUE_APP_S3_PATH}${path}`
        },
        // モーダル表示
        async modal_control(){
            console.log("aaa")
            this.block_data.modal_condition=1
        },
        // 購入処理
        async point_purchase(){
            // PayPay決済
            this.block_data.modal_condition = 0
            this.is_request = true
            let url = `user/scratch/customer/paypay/create/${this.scratch_data.hash_id}`
            let response = await this.$commonFunction.axios_get(url)
            if(response.status === 200){
                window.location.href = response.data;
            } else {
                this.$router.push({name:"ServerErrorPage"});
            }
        },
        // テキストに改行
        decorate_text(text){
            return text.replace(/(.{10})/g, '$1<br>');
        }
    }
}
</script>

<style>    
    /* 背景画像など、基礎設定 */
    .image_box {position: relative;width: 100%;height: 300px; display: flex;justify-content: center;align-items: center;}
    .image_box .button_box{width: 260px;height: 50px;position: absolute;top: 275%;left: 50%;transform: translate(-50%, -50%);border: 1px solid black;border-radius: 20px;}
    .image_box .button_box button{margin: 5px;width: 250px;height: 38px;color: white;border: none;font-size: 1.2rem;font-weight: bold;border-radius: 20px;}
    #bg_image{z-index: -3;width: 100%;max-width: 750px;height: 1200px;display: block;position: absolute;top: 130%;left: 50%;transform: translate(-50%, -50%);}

    /* トップのロゴ画像 */
    .image_box #temp_logo_box {width: 240px;height: auto;position: absolute;top: 10px;}
    .image_box #temp_logo_box img {position: relative;width: 100%;height: auto;top: 0;left: 0;transform: none;}

    /* 賞品表示 */
    .prize_box{z-index: 1;width: 350px;height: 400px;display: block;position: absolute;top: 350px;left: 50%;padding: 30px;transform: translate(-50%, -50%);}
    .prize_box p{width: 80%; color: white;text-align: center;position: relative;z-index: 1;margin: 0 auto;}
    .prize_box h1{font-size: 1.3rem;font-weight: bold;color: white;text-align: center;position: relative;z-index: 1;margin: 40px auto 20px auto;}
    .prize_row{width: 85%;display: flex;justify-content: space-between;margin: 8px auto;}
    .prize_row img{width: 35px;height: auto;top: 0;left: 0;position: relative;transform: none;}
    .prize_box .prize_row p:last-child{text-align: center;white-space: nowrap;align-self: center;} 
    
    .prize_box .prize_bg_img {width: 350px;height: 660px;top: 77%;transform: translate(-50%, -50%);position: absolute;left: 50%;}
    .scratch_sample{width: 150px;height: 150px;top: 60%;transform: translate(-50%, -50%);position: absolute;left: 50%;}
</style>