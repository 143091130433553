
<template>
  <div id="customer_foot" class="footer sp" :style="{ transform: isHidden ? 'translateY(100%)' : 'translateY(0)' }">
    <div 
    class="footer_flex" 
    :style="{ backgroundColor: scratch_data.color }"
    >
      <div class="foot_block">
        <a @click="link_to('CustomerTopPage')">
          <img src="/img/icon-home.png" alt="Image 1">
        </a>
        <p>HOME</p>
      </div>
      <div class="foot_block">
        <a @click="link_to('CustomerPrizePage')">
          <img src="/img/icon-prize.png" alt="Image 1">
        </a>
        <p>獲得賞品</p>
      </div>
      <div class="foot_block">
        <a @click="link_to('CustomerTOSPage')">
          <img src="/img/icon-notice.png" alt="Image 1">
        </a>
        <p>利用規約</p>
      </div>
    </div>
  </div> 
</template>

<script>
    export default {
      props: ["template_id"],
      data() {
        return {
          scratch_data:{color:"white"},
          isHidden: false,
          lastScrollTop: 0,
        };
      },
      mounted() {
        window.addEventListener('scroll', this.onScroll);
        this.process()
      },
      destroyed() {
        window.removeEventListener('scroll', this.onScroll);
      },
      methods: {
        async process(){
            // くじの情報を取得する
            let response = await this.$commonFunction.axios_get(`customer/scratch/detail/${this.$route.query.sid}`)
            if(response.status === 200 && response.data.item_data.length > 0){
                this.scratch_data = response.data.item_data[0]
            } else {
              this.scratch_data.color = "#000000"
            }
        },
        onScroll() {
          const st = window.pageYOffset || document.documentElement.scrollTop;
          this.isHidden = st > this.lastScrollTop;
          this.lastScrollTop = st <= 0 ? 0 : st;
        },
        link_to(page_name){
          let query_parm = {
                sid:this.$route.query.sid,
            }
          this.$router.push({name:page_name,query:query_parm});
        },
      }
    };
</script>

<style>
  #customer_foot{
    width: 100%;
    height: auto;
    display: block;
    bottom: 0;
    position: fixed;
    transition: transform 0.5s ease-out;
    z-index: 999;
  }
  #customer_scratch .footer{top: 1070px;}
  #customer_foot .footer_flex{display: flex;justify-content: space-around;align-items: center;padding: 2px;}
  #customer_foot .foot_block {flex: 1;  display: flex;  flex-direction: column;  align-items: center;  padding: 3px;  color: white;}
  #customer_foot .foot_block p{  flex: 1;  display: flex;  flex-direction: column;  align-items: center;  padding: 5px;  color: white;}
  #customer_foot .foot_block img {    width: 50px;height: auto;  margin-bottom: 0;}
  #customer_foot .icon_menu{display: flex;}
  #customer_foot .icon_menu img{width: 15px;margin-right: 5px;}

  


</style>