<template>
  <div class="image_block" id="prize_list">
      <div class="image_box">
        <img id="bg_image" :src="$commonFunction.return_s3_path(scratch_data.bg_image)">
        <div class="container">
          <h2>獲得賞品一覧</h2>
          <p>賞品を引き換える際は「交換する」ボタンを押して店員にお見せください
          </p>
          <p style="color: red;">※ 一度「使用」してしまうと「交換済み」の表示になってしまいます。<br>ご注意ください。</p>
          <div 
          class="prize_list" 
          v-for="data in prize_data" 
          v-show="show_prize(data)"
          :style="{ backgroundColor: data.color }"
          >
            <div class="icon_box">
              <img v-if="data.prize_code == '1等'" :src="$commonFunction.return_s3_path(scratch_data.prize1_image)">
              <img v-if="data.prize_code == '2等'" :src="$commonFunction.return_s3_path(scratch_data.prize2_image)">
              <img v-if="data.prize_code == '3等'" :src="$commonFunction.return_s3_path(scratch_data.prize3_image)">
              <img v-if="data.prize_code == '4等'" :src="$commonFunction.return_s3_path(scratch_data.prize4_image)">
              <img v-if="data.prize_code == '5等'" :src="$commonFunction.return_s3_path(scratch_data.prize5_image)">
              <p style="margin: 3px 10px;">{{data.prize_code}}</p>
            </div>
            <p style="margin: 3px">{{$commonFunction.date_format(data.created)}}</p>
            <button @click="data_select(data)">{{ data.status }}</button>
          </div>
        </div>
      </div>
      <!-- モーダル  -->
      <div id="overlay" v-if="modal_condition != 0">
        <div class="modal-content fadeIn width_middle" v-if="modal_condition == 1">
          <div class="modal-main">
            <div v-if="selected_data.status == '交換する'">
              <h2>賞品を利用します。<br>下記「使用」ボタンを押した後に表示される画面を担当者にお見せください</h2>
              <p style="text-align: center;">賞品名 : {{selected_data.prize_label}}</p><br>
              <p style="text-align: center;">id : {{selected_data.scratch_code}}</p><br>
              <div class="button_box">
                <button @click="use_prize" class="btn_blue">使用</button>
                <button @click="modal_control(0)" class="btn_silver">キャンセル</button>
                <p class="error_message">{{error_message}}</p>
              </div>
            </div>
            <div v-if="selected_data.status == '交換済み'">
              <h2>景品確認</h2>
              <p style="text-align: center;">id : {{selected_data.scratch_code}}</p><br>
              <p style="text-align: center;color: red;">※ 本景品は交換済みです</p>
              <div class="button_box">
                <button @click="modal_control(0)" class="btn_silver">キャンセル</button>
              </div>
            </div>
            <div v-if="selected_data.status == '期限切れ'">
              <h2>景品確認</h2>
              <p style="text-align: center;color: red;">※ 本景品は有効期限が切れています</p>
              <div class="button_box">
                <button @click="modal_control(0)" class="btn_silver">キャンセル</button>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  <CustomerFooterBlock :template_id="scratch_data.template_id" class="print_ignore"></CustomerFooterBlock>
</template>
  
<script>
import CustomerFooterBlock from "@/components/blocks/custom/CustomerFooterBlock";
export default {
    components: {
        CustomerFooterBlock
    },      
    data() {
        return {
          modal_condition:0,
          prize_data:[],
          selected_data:{},
          error_message:"",
          scratch_data:[]
        }
      },
      created () {
        this.process()
      },
      methods: {
        async process(){
            this.init_scratch();
        },
        // 賞品一覧を取得
        async init_scratch() {
          let storedUUID = localStorage.getItem('uniqueUUID');
          let post_data = {
            uuid:storedUUID,
            scratch_id:this.$route.query.sid,
          }
          // くじのデータを取得
          let response = await this.$commonFunction.axios_get(`customer/scratch/detail/${this.$route.query.sid}`)
          if(response.status === 200){
            this.scratch_data = response.data.item_data[0]
          } else if (response.status == 500){
            this.$router.push({name:"ServerErrorPage"});
          }
          response = await this.$commonFunction.axios_post(`customer/scratch/prize/list`,post_data)
          if(response.status == 200 && response.data.item_data){
            response.data.item_data.forEach(item => {
              let created = new Date(item.created);
              created.setDate(created.getDate() + 30);
              let deadline = created.toISOString();
              const date = new Date(deadline);
              const now = new Date();
              const is_disable = date < now
              if(item.is_used == "使用済み"){
                item["status"] = "交換済み"
                item["color"] = "gray";
              } else if(item.is_used == "未使用" && is_disable){
                item["status"] = "期限切れ"
                item["color"] = "gray"
              } else if(item.is_used == "未使用" && !is_disable){
                item["color"] = this.scratch_data.color;
                item["status"] = "交換する"
              }
              // 景品名称に変換
              if(item.prize_code == "1等"){
                item["prize_label"] = this.scratch_data.first_prize_label
              } else if(item.prize_code == "2等"){
                item["prize_label"] = this.scratch_data.second_prize_label
              } else if(item.prize_code == "3等"){
                item["prize_label"] = this.scratch_data.third_prize_label
              } else if(item.prize_code == "4等"){
                item["prize_label"] = this.scratch_data.fourth_prize_label
              } else if(item.prize_code == "5等"){
                item["prize_label"] = this.scratch_data.fifth_prize_label
              }
            });
            this.prize_data = response.data.item_data
          } else if (response.status == 500){
            this.$router.push({name:"ServerErrorPage"});
          }
        },
        // 賞品の使用
        async use_prize(){
          let storedUUID = localStorage.getItem('uniqueUUID');
          let post_data = {
            prize_id:this.selected_data.id,
            uuid:storedUUID,
          }
          let response = await this.$commonFunction.axios_post(`customer/scratch/prize/use`,post_data)
          if(response.status == 200){
            let query_parm = {
              page_type:1,
              code:this.selected_data.scratch_code,
              sid:this.$route.query.sid,
            }
            this.$router.push({name:'CustomerThanksPage', query:query_parm});
          } else {
            this.error_message = "この賞品は使用できません"
          }
          // this.$router.push({name:'CustomerTopPage'});
        },
        // モーダル表示
        modal_control(condition){
          this.modal_condition = condition
        },
        data_select(data){
          this.selected_data = data
          this.modal_condition = 1
        },
        // 景品表示判定
        show_prize(data){
          let condition
          if(data.prize_code != "5等"){
            condition = true
          } else if(data.prize_code === "5等" && data.miss_prize_type === 1){
            condition = true
          } else {
            condition = false
          }
          return condition
        },
      }
  };
  </script>

<style>
  /* 賞品ページ基本 */
  #prize_list .container {position: absolute;width: 370px;top: 10px;}
  #prize_list .container h2 {color: black;font-weight: bold;font-size: 1.5rem;text-align: center;}
  #prize_list .container p {color: black;margin: 20px;}

  /* 賞品一覧 */
  #prize_list .prize_list {display: flex;font-weight: bold;justify-content: space-between;padding: 10px;margin: 10px;border-radius: 20px;}
  #prize_list .prize_list p{margin: 0;color: white;}
  #prize_list .icon_box{display: flex;}
  #prize_list .icon_box img{position: relative;width: 20px;height: 20px;top: 0;left: 0;transform: none;}
  #prize_list .prize_list button{background: #C9C9C9;border-radius: 20px;box-shadow: none;padding: 5px 10px;font-weight: bold;border: none;}

  /* モーダル関連 */
  #prize_list .modal-content {width: 300px;height: 250px;display: block;position: absolute;top: 30%;left: 50%;transform: translate(-50%, -50%);}
  #prize_list .modal-main h2 {width: 80%;margin: 20px auto;}
  #prize_list #overlay .button_box {width: 70%;}
  #prize_list #overlay .button_box button{height: 40px;margin-bottom: 10px;}
</style>
  
  