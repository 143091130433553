<template>
  <div class="image_block" id="tos_page">
      <div class="image_box" id="tokusho_height">
        <div class="container">
          <h2>- 特定商取引法に基づく表記 -</h2>
          <h3>販売事業者の名称及び所在地</h3>
          <p>
            株式会社CBCコミュニケーションズ<br>
            愛知県名古屋市中区新栄1-2-8<br>
          </p>
          <h3>代表者氏名</h3>
          <p>
            佐々部 浩
          </p>
          <h3>販売責任者氏名</h3>
          <p>
            都田 和久
          </p>
          <h3>問い合わせ先</h3>
          <p>
            お問い合わせの受付、回答はメールにて行っております。<br>
            メールアドレス: toda.kazuhisa@cbc-com.co.jp
          </p>
          <h3>販売価格</h3>
          <p>
            購入手続きの際、都度画面に表示いたします。
          </p>
          <h3>販売価格以外に発生する料金</h3>
          <p>
            本サービスに関するコンテンツのダウンロード及びアプリケーション動作等に必要となるインターネット接続料、通信料（パケット通信料）等はお客様の負担となります。それぞれの料金は、お客様がご利用のインターネットプロバイダー又は携帯電話会社にお問い合わせください。
          </p>
          <h3>お支払い時期・方法</h3>
          <p>
            お客様が選択した支払方法に関する、プラットフォーム事業者の規約に基づきます。
          </p>
          <h3>提供時期</h3>
          <p>
            購入確定後、すぐにご利用いただけます。
          </p>
          <h3>返品等（キャンセル）について</h3>
          <p>
            デジタルコンテンツの特性上、購入確定後の返品・交換には応じられません。
          </p>
          <h3>対応機種・動作環境</h3>
          <p>
            本サービスが動作するAndroid端末、iOS端末のいずれかが必要です。<br>
            動作環境は各アプリストアにてご確認ください。
          </p>
          <h2>- 資金決済に関する法律 -</h2>
          <h3>サービス名</h3>
          <p>WIN-WIN カード</p>
          <h3>前払式支払手段の名称</h3>
          <p>PayPay Webペイメント</p>
          <h3>発行事業者</h3>
          <p>
            事業者名　株式会社CBCコミュニケーションズ<br>
            住所　愛知県名古屋市中区新栄1-2-8<br>
          </p>
          <h3>支払可能金額等</h3>
          <p>
            支払可能金額は50万円です。残高の上限額は100万円となります。
          </p>
          <h3>有効期限</h3>
          <p>
            有効期限は配布後、30日後23:59です。
          </p>
          <h3>利用できる場所</h3>
          <p>
            「WIN-WIN カード」サービス設置店でご利用いただけます。
          </p>
          <h3>利用上の注意</h3>
          <p>
            ※原則、商品販売後の払い戻しはいたしません。<br>
            ただし、各サービスの提供を終了する場合は、この限りではありません。<br>
            ※システム障害時やメンテナンス等により、一時的に商品の利用ができない場合があります。<br>
            ※商品の利用条件は、アプリケーションの利用規約をご確認ください。<br>
            ※その他、利用規約が適用されますので、利用規約の内容をご確認ください。
          </p>
          <br>
          <div class="button_box">
            <button @click="link_to" style="background-color: rgb(98, 239, 222);">
                TOPに戻る
            </button>
            <p class="error_message">{{error_message}}</p>
          </div>
        </div>
      </div>
    <FooterBlock></FooterBlock>
  </div>
</template>
  
<script>
  export default {
    components: {
    },
      data() {
        return {
          scratch_data:[]
        }
      },
      created () {
      },
      methods: {
        link_to(){
          if(this.$route.query.sid){
            let query_parm = {
              sid:this.$route.query.sid,
            }
            this.$router.push({name:'CustomerTopPage', query:query_parm});
            } else {
            this.$router.push({name:'SampleTopPage'});
          }
        }
      }
  };
</script> 

<style>
  #tos_page #tokusho_height{
    height: 1750px;
  }
  @media (max-width: 600px) {
    #tos_page #tokusho_height{
      height: 2050px;
    }
  }
</style>